<template>
  <base-page>
    <titlu-pagina
      Titlu="Orders"
      @on_add_clicked="show_dialog()"
      :AdaugaVisible="true" />
    <el-card style="margin: 5px 0px 5px 0px">
      <div slot="header" class="clearfix">
        <strong> Filters </strong>
      </div>
      <div class="filtre">
        <el-form @submit.prevent.native="refresh_info()">
          <el-row :gutter="20">
            <el-col :span="4">
              <el-form-item label="Order number">
                <el-input v-model="Filters.OrderId" />
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-form-item label="Table">
                <el-select class="full-width" v-model="Filters.IdTable">
                  <el-option
                    v-for="item in Info.tables"
                    :key="'tables' + item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="6.5">
              <el-form-item label="Created At">
                <br />
                <el-date-picker
                  v-model="Filters.CreatedAt"
                  type="datetimerange"
                  class="full-width"
                  :span="12"
                  range-separator="-"
                  format="dd/MM/yyyy HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  start-placeholder="Data start"
                  end-placeholder="Data end"
                  :default-time="['00:00:00', '23:59:00']"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="Closed At">
                <br />
                <el-date-picker
                  v-model="Filters.ClosedAt"
                  type="datetimerange"
                  class="full-width"
                  :span="12"
                  range-separator="-"
                  format="dd/MM/yyyy HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  start-placeholder="Data start"
                  end-placeholder="Data end"
                  :default-time="['00:00:00', '23:59:00']"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="4">
              <el-form-item label="Closed">
                <el-select class="full-width" v-model="Filters.Closed">
                  <el-option :key="0" label="No" value="0"></el-option>
                  <el-option :key="1" label="Yes" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :md="24">
              <el-button
                type="primary"
                native-type="submit"
                @click="refresh_info()"
              >
                Apply
              </el-button>
              <el-button type="danger" native-type="submit" @click="reset()">
                Reset
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>

    <el-table :data="Results">
      <el-table-column prop="Id" label="Order Number">
        <template slot-scope="scope"> Order #{{ scope.row.Id }} </template>
      </el-table-column>
      <el-table-column label="Created At">
        <template slot-scope="scope">
          {{ scope.row.CreatedAt | momentformat("dd.MM.YYYY HH:mm") }}
        </template> </el-table-column
      ><el-table-column label="Closed At">
        <template slot-scope="scope">
          {{ scope.row.ClosedAt | momentformat("dd.MM.YYYY HH:mm") }}
        </template> </el-table-column
      ><el-table-column prop="TableName" label="Table"></el-table-column
      ><el-table-column prop="Closed" label="Closed"></el-table-column
      ><el-table-column fixed="right" label="Actions" width="200px">
        <template slot-scope="scope">
          <el-tooltip content="Print">
            <el-button
              type="warning"
              icon="el-icon-printer"
              circle
              @click="print(scope.row.Id)"
            />
          </el-tooltip>
          <el-tooltip content="Edit">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="show_dialog(scope.row.Id)"
            />
          </el-tooltip>

          <el-tooltip content="Delete">
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="delete_item(scope.row)"
            />
          </el-tooltip> </template></el-table-column
    ></el-table>
    <el-pagination
      @size-change="refresh_info"
      @current-change="refresh_info"
      :page-size.sync="PaginationInfo.PerPage"
      :current-page.sync="PaginationInfo.Page"
      :total="PaginationInfo.RowCount"
      layout="pager" />
    <Orders-dialog ref="dlg" @save="refresh_info()"
  /></base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Orders_dialog from "@/pages/orders/Orders_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";

export default {
  name: "orders",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "Orders-dialog": Orders_dialog,
    "titlu-pagina": TitluPagina,
  },
  created() {
    this.$root.$refs.Orders_dialog = this;
  },
  data() {
    return {
      Results: [],
      base_url: "",
      Info: {
        tables: [],
      },
      Filters: {
        OrderId: "",
        IdTable: "",
        CreatedAt: ["", ""],
        ClosedAt: ["", ""],
        Closed: "",
      },
      Settings: {},
      OrderBy: {},
      PaginationInfo: {
        Page: 1,
        PerPage: 50,
        RowCount: 0,
        PageSizes: [10, 25, 50, 100, 200],
      },
    };
  },
  methods: {
    async total(price) {
      let vat = Number(this.Settings.vat[0].Value);
      let total = (vat / 100) * price;
      return price + total;
    },

    async print(id) {
      let response = await this.post("orders/print", { id: id });
      let props = this.Results.find((label) => label.Id === id);
      let data = {
        order_number: props.Id,
        table: props.TableName,
        createdAt: props.CreatedAt,
        products: response,
      };

      let total =
        data.products.map((d) => 1 * d.Price).reduce((p, c) => c + p, 0) +
          data.products
            .map((pr) =>
              pr.Extra.map((extra) => Number(extra.Price)).reduce(
                (p, c) => p + c,
                0
              )
            )
            .reduce((p, c) => p + c, 0) || 0;
      let totalWithVat = await this.total(total);
      total = total.toFixed(2);
      totalWithVat = totalWithVat.toFixed(2);

      let printer = window.open(
        "_blank",
        "Order",
        "fullscreen=yes,scrollbars=yes"
      );
      await printer.document.write(`
              <html>
              <head>
                <meta charset="utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1">
                
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet" meida="all" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous">

                <title>5dollarcafe</title>
              </head>
              <body>
                <header style="background-color: #333333; height: 100px; border-bottom:5px solid #202020;">
                    <div class="container">
                        <div class="row w-100">
                            <div class="col-12 align-items-center d-flex justify-content-center">
                                <div style="margin-top: 30px; background-color: #333333; border-radius: 50%; border: 5px solid #202020">
                                <img src="https://5dollarcafe.com/wp-content/uploads/2019/06/logo-mic-1.png" style="width: 250px;">

                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-top: 30px; ">
                                    <div class="col-6 d-flex jalign-items-start flex-column " style="font-size: 12px;">
                                        <p><strong>Address</strong> 2200 S Las Vegas Blvd, Las Vegas, NV 89102, USA</p>
                                        <p><strong>Phone</strong> 702-333-0030​​</p>
                                        <p><strong>Email</strong> office@5dollarcafe.com</p>


                                    </div>
                                    <div class="col-6 d-flex align-items-end flex-column " style="font-size: 12px;">
                                        <br>
                                        <p><strong>Order number</strong> ${
                                          data.order_number
                                        }</p>
                                        <p><strong>Table</strong> ${
                                          data.table
                                        }</p>
                                        <p><strong>Date and time</strong> ${
                                          data.createdAt
                                        }</p>
                                    </div>
                        </div>
                        <div class="row" style="margin-top: 100px;">
                            <table class="table" style="font-size: 12px;">
                                <thead>
                                  <tr>

                                    <th scope="col">Product</th>
                                    <th scope="col" class="text-end">Total</th>

                                  </tr>
                                </thead>
                                <tbody>
                                   ${data.products
                                     .map(
                                       (product) => `
                                    <tr>
                                      <td>${product.Name}</td>
                                      <td class="text-end">${(
                                        product.Price * 1
                                      ).toFixed(2)} $</td>
                                    </tr>
                                    ${
                                      product.Extra.length > 0
                                        ? product.Extra.map(
                                            (extra) => `<tr>
                                      <td style="font-size: 10px" colspan="1">Extra: ${extra.Name}</td>

                                      <td style="font-size: 10px" class="text-end">${extra.Price} $</td></tr>
                                    `
                                          ).join("\n")
                                        : ""
                                    }
                                    `
                                     )
                                     .join("\n")}
                                </tbody>
                              </table>
                        </div>
                        <div class="row" style="margin-top: 100px;">
                            <div class="col-12 d-flex align-items-end flex-column">
                                <h4>Subtotal: ${total} $</h3>
                                <h2>Total: ${totalWithVat} $</h1>
                            </div>
                        </div>
                    </div>
                </header>
              </body>
            </html>
      `);

      setTimeout(() => {
        printer.print();
      }, 2000);
    },
    async get_info() {
      var response = await this.post("orders/get_info");
      this.Info.tables = response.tables;
      if (this.Filters.IdTable.length > 0)
        this.Filters.IdTable = this.Info.tables[0].Id;
      this.refresh_info();
    },

    async refresh_info() {
      var response = await this.post("orders/index", {
        Filters: this.Filters,
        OrderBy: this.OrderBy,
        PaginationInfo: this.PaginationInfo,
      });
      this.Results = response.Results;
      this.PaginationInfo = response.PaginationInfo;
      this.Settings.vat = response.VAT;
      this.select_menu_item("orders");
    },
    reset() {
      this.Filters = {
        OrderId: "",
        IdTable: "",
        CreatedAt: ["", ""],
        ClosedAt: ["", ""],
        Closed: "",
      };
      this.refresh_info();
    },

    async delete_item(item) {
      let confirm = null;

      try {
        confirm = await this.$confirm(`Are you sure?`, "Warning");
      } catch (e) {
        confirm = null;
      }

      if (confirm) {
        await this.post("orders/delete_item", {
          Id: item.Id,
          IdTable: item.IdTable,
        });
        this.refresh_info();
      }
    },

    show_dialog(id) {
      this.$refs.dlg.show_me(id);
    },
  },
  mounted() {
    this.base_url = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.top50 {
  margin-top: 20px;
}

.filtre {
  .el-input-number {
    width: 100% !important;
  }
}
</style>